<template>
    <layout class="index-ma-wrapper">
        <!-- 面包屑 -->
        <div class="container mobile-bread mt15 flex x-left">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/special-ma/index/'+$route.params.id}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>相关论文</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container index-container flex mt20">
            <a-spin :spinning="loading" tip="Loading..." class="home-left">
                <div class="" v-show="!loading">
                    <div v-for="(item, index) in paperList" :key="index" class="paper-list pb20 t-l"
                            :class="{ 'mt20': index != 0 }" @click="goArticleDetail(item.id)">
                            <p class="fs16 color333 strong mb15" style="line-height:1.3;">{{ item.title || item.title_en }}</p>
                            <!-- <p class="fs12 color333 mb5">浏览：{{ item.visits_num || 0 }}次</p> -->
                            <div class="flex x-left color333 fs12 row-1 mb5" v-if="item.author_real_name || item.org_cnname">
                                作者：
                                <span v-if="item.real_name" class="mr10"><span class="color333">{{ item.author_real_name }}</span></span>
                                <span v-if="item.org_cnname" class="color999">{{ item.org_cnname }}</span>
                            </div>
                            <p v-if="item.keywords" class="fs12 color333 mb10">关键词：{{ item.keywords }}</p>
                        </div>
                </div>
                <div class="t-c mt20" v-if="listCount > 0 && !loading">
                    <a-pagination :total="listCount" v-model:current="params.page" :page-size="params.limit" :show-size-changer="false" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
                <a-empty v-if="!paperList.length && !loading" />
            </a-spin>
            <div class="home-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { committee_id } from '@/config';

export default {
    name: 'SpecialMaArticleList',
    components: {
        layout,
        layoutRight,
    },
    data() {
        return {
            loading: false,
            params: {
                limit: 10, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            },
            listCount:0,
            paperList: [], //论文列表
            layoutRightConfig: {
                recommendExpert: {
                    show: true, // 是否显示
                    title: '推荐大咖', // 标题
                    methods: "post",
                    moreLink: '/special-ma/expert-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        limit: 3, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        pageSize: 3, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                // 推荐期刊
                periodicalRank: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '推荐期刊', // 标题
                    moreLink: '/special-ma/periodical-list/'+this.$route.params.id,
                    detailLink:'/special-ma/periodical-detail/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        limit: 3, 
                        topic_id: this.$route.params.id, 
                        page: 1,
                        topic_committee_id:committee_id
                    },   // 参数条件
                }
            }
        }
    },
    computed: {

    },
    methods: {
        getArticleList(){
            this.loading = true
            this.request.post('GetMeetingPaper', this.params).then(res => {
                this.listCount = Number(res.data.count)
                this.paperList = res.data.list
                this.loading = false
            })
        },
        // 论文详情
        goArticleDetail(id) {
            this.$router.push({
                path: '/special-ma/article-detail/'+this.$route.params.id,
                query: {
                    id
                }
            })
        },
        handlePageChangeEvent(page, pageSize){
            window.scrollTo(0,0);
            this.params.page = page
            this.params.limit = pageSize
            this.getArticleList()
        }
    },
    created() {
        this.getArticleList()
    },
    mounted() {
        
    },

    // 缓存页面二次进入的钩子函数
    activated() {

    },
}
</script>
<style lang="less" scoped>
.index-ma-wrapper {
    .home-left {
        width: calc(66.66% - 30px);

        .paper-list {
            cursor: pointer;
            border-bottom: 1px dashed #d8d8d8;

            &:last-child {
                border: 0;
            }
        }
    }

    .home-right {
        width: 33.33%;
        margin-top: -10px;
    }
}

@media screen and (max-width:768px) {
    .container {
        display: block;
        padding: 0 10px;
    }
    .index-ma-wrapper {
        .mobile-bread{display: none;}
        .home-left,
        .home-right {
            width: 100%;
        }

        .home-left {
            .swiper-content {
                flex-wrap: wrap;

                .committee-item {
                    width: calc(50% - 10px) !important;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}</style>
